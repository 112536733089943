.footer {
	position: relative;
	padding: 4em 0 1.2em;
}

.wrapper {
	position: relative;
	width: 92%;
	max-width: 100em;
	margin: auto;
}

.footer p {
	position: relative;
	text-align: center;
	font-weight: 600;
	font-size: 0.9em;
	padding: 2em 0 0;
}

.footerContent {
	width: 100%;
	height: 100%;
	display: flex;
	gap: 2em;
	justify-content: space-between;
	margin: 0 auto;
}

.desc {
	width: 40%;
	position: relative;
}

.desc ul {
	list-style: none;
	width: 100%;
	margin: 0 0 1em;
}

.desc ul li {
	width: fit-content;
	display: flex;
	align-items: center;
}

.desc ul li a {
	color: var(--text-color);
	padding: 0;
}

.desc ul li a:hover {
	text-decoration: underline;
	background: none;
	border-radius: none;
	color: var(--text-color);
}

.logo img {
	width: 10em;
	margin-bottom: 1.2em;
}

.desc .map {
	position: relative;
	width: 100%;
	height: 10em;
}

.desc h3 {
	position: relative;
	font-size: 1.2em;
	margin-bottom: 0.4em;
}

.desc .map iframe {
	position: relative;
	height: 100%;
	width: 100%;
	border-radius: 10px;
}

.footerLinks {
	width: 60%;
	display: flex;
	gap: 2em;
}

.socialMedia {
	position: relative;
	width: fit-content;
	display: flex;
	flex-direction: column;
	gap: 1.2em;
	margin: 2em 0.7em;
}

.instagram,
.facebook,
.whatsapp {
	color: var(--text-color);
	font-size: 1.6em;
	transition: color 0.3s ease;
}

.whatsapp:hover {
	color: var(--whatsapp-color);
}

.facebook:hover {
	color: var(--facebook-color);
}

.instagram:hover {
	color: var(--instagram-color);
}

@media (max-width: 700px) {
	.footerContent {
		row-gap: 4em;
	}
	.desc {
		width: 100%;
		text-align: left;
	}

	.footerLinks {
		width: 100%;
		flex-direction: column;
		text-align: left;
	}
}

.footerSection {
	position: relative;
	width: 100%;
}

.footerSection h3 {
	position: relative;
	width: fit-content;
	padding-bottom: 0.2em;
	font-size: 1.1em;
}

.footerSection h3::before {
	position: absolute;
	content: '';
	bottom: 0;
	left: 0;
	height: 3px;
	width: 100%;
	background: var(--secondary-color);
	transition: 0.3s;
	border-radius: 1em;
}

.footerSection ul {
	list-style-type: none;
	margin: 0;
}

.footerSection ul li {
	width: fit-content;
	margin-bottom: 8px;
}

.footerSection ul li a {
	color: var(--text-color);
	text-decoration: none;
	padding: 0;
	margin-top: 1em;
}

.footerSection ul li a:hover {
	text-decoration: underline;
	background: none;
	border-radius: none;
	color: var(--text-color);
}

/* Responsive */
@media only screen and (max-width: 600px) {
	.footerContent {
		flex-direction: column;
		text-align: center;
	}

	.footerSection {
		margin-right: 0;
		margin-bottom: 20px;
	}

	.footerSection ul li {
		margin-bottom: 5px;
	}
}
