.main {
	position: relative;
	width: 100%;
}

.container {
	position: relative;
	width: 100%;
	height: auto;
	display: flex;
	gap: 1em;
}

.wrapperClass {
	position: relative;
	width: 70%;
}

.overviewSection {
	position: relative;
	width: 100%;
	display: flex;
	gap: 1em;
}

.overviewCard {
	position: relative;
	width: 14em;
	height: fit-content;
	background: var(--white-color);
	padding: 0.7em 1em;
	border-radius: 10px;
	box-shadow: 0 2px 4px var(--ash-color);
	display: flex;
	gap: 0.8em;
}

.car,
.carNew,
.carUsed {
	position: relative;
	font-size: 1.2em;
	padding: 0.5em 0.5em;
	border-radius: 10em;
}

.car {
	color: var(--secondary-color);
	background: var(--secondary-light);
}
.carNew {
	color: var(--blue-color);
	background: var(--blue-light);
}
.carUsed {
	color: var(--primary-color);
	background: var(--primary-light);
}

.overviewCard .intro {
	position: relative;
	width: fit-content;
	padding: 0;
}

.overviewCard .intro h2 {
	margin-bottom: 10px;
	width: fit-content;
	font-size: 1.16em;
	font-weight: 800;
	color: var(--text-color);
	padding: 0;
	margin: 0;
}

.overviewCard .intro p {
	position: relative;
	width: fit-content;
	font-size: 0.88em;
	padding: 0;
	margin: 0;
}

.recentActivities {
	position: relative;
	width: 100%;
	margin: 1em 0;
}

.recentActivities h4 {
	position: relative;
	font-size: 1.2em;
	margin-bottom: 0.5em;
}

.recentCards {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1em;
	align-items: center;
}

.recentCard {
	position: relative;
	width: 100%;
	height: 6.5em;
	display: flex;
	gap: 1em;
	background: var(--white-color);
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 2px 4px var(--ash-color);
}

.recentCard .image {
	position: relative;
	width: 10em;
	height: auto;
}

.recentCard .image img {
	position: relative;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.recentCard .details {
	position: relative;
	width: calc(100% - 10em);
	height: 100%;
	padding: 1em 0;
}

.recentCard .details h4 {
	position: relative;
	font-size: 1.25em;
	margin: 0;
}

.recentCard .details p {
	position: relative;
	margin: 0;
	padding: 0;
	font-size: 0.88em;
}

.calender {
	position: relative;
	width: 30%;
}

.theCalender {
	position: relative;
	width: 100%;
	height: fit-content;
	border: none !important;
	background: var(--white-color);
	padding: 0.7em 1em;
	border-radius: 10px;
	box-shadow: 0 2px 4px var(--ash-color);
}

@media (max-width: 780px) {
	.container {
		flex-direction: column;
	}

	.overviewSection {
		flex-direction: column;
	}
}
