.main {
	position: relative;
	width: 100%;
	height: auto;
	padding: 10em 0;
}

.wrapper {
	position: relative;
	width: 94%;
	max-width: 100em;
	margin: auto;
}

.container {
	position: relative;
	width: 24em;
	margin: 0 auto;
	padding: 0.4em 2em 2em;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.container h2 {
	position: relative;
	width: 100%;
	text-align: center;
	font-size: 2em;
}

.form {
	position: relative;
	display: flex;
	flex-direction: column;
}

.formGroup {
	position: relative;
}

.formGroup label {
	position: relative;
	font-size: 0.9em;
	font-weight: 400;
	line-height: 24px;
	color: var(--text-color);
}

.formGroup input {
	position: relative;
	width: 100%;
	font-size: 1.1em;
	padding: 0.6em 1.4em;
	border: 1px solid var(--border-color);
	border-radius: 10em;
	color: var(--text-color);
}

.passwordContainer {
	position: relative;
}

.passwordContainer button {
	position: absolute;
	right: 1em;
	bottom: 1.1em;
	background: none;
	padding: 0;
	margin: 0;
	border: none;
}

.passwordContainer button:hover {
	background: none;
}

.eye {
	position: relative;
	font-size: 0.9em;
	color: var(--shadow-color);
	margin: 0;
}

.error {
	color: var(--danger-color);
	font-size: 0.875em;
	margin-top: 5px;
}

.registerButton {
	padding: 10px;
	background: var(--secondary-color);
	color: var(--white-color);
	border: none;
	border-radius: 10em;
	cursor: pointer;
	margin: 2em 0 0;
}

.registerButton:hover {
	background: var(--secondary-dark);
}

.registerButton:disabled {
	background-color: var(--ash-color);
	cursor: not-allowed;
}

.signin {
	margin-top: 15px;
}

.signin a {
	color: var(--blue-color);
	text-decoration: none;
}

.signin a:hover {
	text-decoration: underline;
}

@media (max-width: 780px) {
	.container {
		width: 100%;
	}
}
