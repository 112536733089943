.main {
  position: relative;
  padding: 4em 0;
  max-width: 800px;
  margin: auto;
  line-height: 1.6;
}

.wrapper {
  position: relative;
  width: 94%;
  max-width: 100em;
  margin: auto;
}

.main h1,
.main h2 {
  color: var(--text-color);
}

.main h1 {
  font-size: 2em;
  margin-bottom: 0.5em;
}

.main h2 {
  font-size: 1.5em;
  margin-top: 1em;
  margin-bottom: 0.5em;
}

p {
  margin-bottom: 1em;
}

ul {
  margin-left: 20px;
  list-style-type: disc;
}

strong {
  font-weight: bold;
}

.collist li,
.uselist li,
.infolist li {
  list-style: disc;
}
