.main {
	position: relative;
}

.wrapper {
	position: relative;
	width: 94%;
	max-width: 100em;
	margin: auto;
}

.heroInvert {
	position: relative;
	width: 100%;
	height: 28em;
	background: url('.././assets/contacts.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 20%;
	transform: scaleX(-1);
}

.hero {
	position: relative;
	width: 94%;
	height: 100%;
	max-width: 100em;
	margin: auto;
	padding: 4em 0;
	transform: scaleX(-1);
}

.hero h1 {
	position: relative;
	width: fit-content;
	top: 50%;
	transform: translateY(-50%);
	font-size: 3em;
	color: var(--text-color);
	background: var(--white-color);
	padding: 0 0.2em 0.1em;
}

.hero h1::before {
	position: absolute;
	content: '';
	bottom: 0;
	left: 0;
	height: 5px;
	width: 100%;
	background: var(--secondary-color);
	transition: 0.3s;
}

.content {
	position: relative;
	padding: 4em 0;
}

h2 {
	font-size: 2.4em;
}

h3 {
	font-size: 1.4em;
}

ul {
	padding: 0.8em 0;
	margin: 0;
}

li {
	list-style: none;
}

.map li a,
.content li a {
	width: fit-content;
	padding: 0;
}

.map li a:hover,
.content li a:hover {
	color: var(--text-color);
	background: none;
}

.map {
	position: relative;
	display: flex;
	gap: 2em;
	justify-content: space-between;
}

.map h3 {
	position: relative;
	width: fit-content;
	font-size: 1.8em;
}

.nakuru h3::before,
.nairobi h3::before {
	position: absolute;
	content: '';
	bottom: 0;
	left: 0;
	height: 3px;
	width: 100%;
	background: var(--secondary-color);
	transition: 0.3s;
	border-radius: 1em;
}

.nakuru,
.nairobi {
	position: relative;
	width: 50%;
}

.map .location {
	position: relative;
	width: 100%;
	height: 20em;
}

.map .nakuru iframe,
.map .nairobi iframe {
	position: relative;
	height: 100%;
	width: 100%;
	border-radius: 10px;
}

@media (max-width: 780px) {
	.map {
		flex-direction: column;
	}

	.nakuru,
	.nairobi {
		position: relative;
		width: 100%;
	}
}
