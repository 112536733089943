.main {
	display: flex;
	height: 100vh;
	width: 100%;
	background-color: var(--light-background-color);
}

.nav {
	width: 220px;
	background-color: var(--white-color);
	box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
	transition: width 0.3s;
}

.dashbody {
	flex: 1;
	padding: 4em 20px 20px;
	overflow-y: auto;
	background-color: var(--light-background-color);
}

h1 {
	font-size: 1.4em;
}
