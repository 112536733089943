.sellcar {
	position: relative;
	padding: 4em 0;
	background: var(--white-color);
	border-radius: 10px;
}

.wrapper {
	position: relative;
	width: 94%;
	max-width: 100em;
	margin: auto;
	display: flex;
	gap: 2em;
	justify-content: space-between;
}

.intro {
	position: relative;
	width: 60%;
	padding: 1em 0;
}

.intro .image {
	position: relative;
	width: 100%;
	height: auto;
}

.intro .image img {
	position: relative;
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 10px;
}

h1,
h2 {
	color: var(--text-color);
}

.intro h1 {
	font-size: 1.8em;
	margin-bottom: 0.5em;
}

.intro h2 {
	font-size: 1.5em;
	margin-top: 1em;
	margin-bottom: 0.5em;
}

li,
p {
	line-height: 24px;
	margin-bottom: 1em;
}

b {
	font-weight: bold;
}

.space {
	margin-top: 2em;
}

.form {
	position: relative;
	width: 40%;
	height: fit-content;
	background: var(--fade-color);
	padding: 1.5em;
	border-radius: 10px;
}

.form h2 {
	font-size: 2em;
	margin: 0 0 0.4em;
}

.form .label {
	position: relative;
	font-size: 0.8em;
	font-weight: 600;
	line-height: 24px;
	color: var(--text-color);
	display: flex;
	align-items: center;
	gap: 1em;
	margin-bottom: 1em;
}

.form .label p {
	position: relative;
	width: 5em;
	padding: 0;
	margin: 0;
}

.form .label input {
	position: relative;
	width: calc(100% - 5em);
	font-size: 1.1em;
	padding: 0.6em 1.4em;
	border: none;
	border-radius: 10em;
	color: var(--text-color);
}

.form .label input:focus {
	outline: none;
}

.form .textarea {
	position: relative;
	font-size: 0.8em;
	font-weight: 600;
	line-height: 24px;
	color: var(--text-color);
	display: flex;
	flex-direction: column;
}

.textarea textarea {
	position: relative;
	width: 100%;
	min-width: 100%;
	min-height: 12em;
	height: auto;
	border-radius: 10px;
	padding: 1em;
	border: none;
	resize: none;
}

.textarea textarea:focus {
	outline: none;
}

.condition {
	position: relative;
	font-size: 0.8em;
	font-weight: 600;
	line-height: 24px;
	color: var(--text-color);
	display: flex;
	align-items: center;
	gap: 1em;
	margin: 1em 0;
}

.condition p {
	position: relative;
	width: 5em;
	padding: 0;
	margin: 0;
}

.condition .select {
	position: relative;
	font-size: 1.1em;
	margin: 0;
	padding: 0.5em 1em;
	border: none;
	border-radius: 10px;
	color: var(--text-color);
}

.condition .select:focus {
	outline: none;
}

.imageSelect {
	position: relative;
	margin: 1em 0;
	width: fit-content;
	font-size: 0.8em;
	font-weight: 600;
	line-height: 24px;
	color: var(--text-color);
	display: flex;
	align-items: center;
	gap: 1em;
	margin-bottom: 1em;
}

.imagePreviews {
	position: relative;
	width: fit-content;
	display: flex;
	gap: 1em;
	align-items: center;
	flex-wrap: wrap;
}

.imagePreview {
	position: relative;
	width: 10em;
	height: 6.7em;
	margin: 0 0 1em;
}

.imagePreview img {
	position: relative;
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 10px;
}

.imagePreview .delete {
	position: absolute;
	width: fit-content;
	right: 0;
	font-size: 0.6em;
	font-weight: 800;
	padding: 0.3em 0.5em;
	margin: 0;
	background: var(--text-color);
	color: var(--white-color);
	border-radius: 10em;
}

.imagePreview .delete:hover {
	cursor: pointer;
}

.form button[type='submit'] {
	padding: 0.5em 1.2em;
	background-color: var(--text-color);
	color: var(--white-color);
	border: none;
	border-radius: 10em;
	cursor: pointer;
	font-size: 1em;
	transition: background-color 0.3s ease;
}

.form button[type='submit']:hover {
	background-color: var(--hover-text-color);
}

.files {
	position: relative;
	opacity: 0;
}

@media (max-width: 700px) {
	.wrapper {
		flex-direction: column;
	}

	.intro,
	.form {
		width: 100%;
	}
}
