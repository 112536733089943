@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");

:root {
  --primary-color: #1e8c10;
  --primary-light: #e6ffe3;
  --primary-dark: #30ce00;

  --secondary-color: #e83020;
  --secondary-light: #ffe5e3;
  --secondary-dark: #ce1100;

  --blue-color: #007bff;
  --blue-light: #e6f2ff;
  --blue-dark: #0056b3;

  --success-color: #28a745;
  --warning-color: #ffc507;
  --danger-color: #dc3545;
  --info-color: #17a2b8;
  --fav-color: #d81878;

  --background-color: #f8f9fa;
  --text-color: #231f1a;
  --shadow-color: #919191;
  --white-color: #fff;
  --ash-color: #e4e4e4;
  --fade-color: #f1f1f1;

  --light-background-color: #f9fafc;
  --border-color: #dddddd;
  --hover-background-color: #e1e1e1;
  --hover-text-color: #000000;
  --body-background-color: #f8f8f8;

  --whatsapp-color: #25d366;
  --facebook-color: #1877f2;
  --twitter-color: #1da1f2;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-margin-top: 10em;
  scroll-behavior: smooth;
  font-family: "Outfit", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

body {
  position: relative;
  margin: 0 auto;
  height: 100%;
  cursor: default;
  background-color: var(--white-color);
  color: var(--text-color);
}

body::-webkit-scrollbar {
  width: 0.6em;
  transition: 6s;
}

body::-webkit-scrollbar:hover {
  width: 0.6em;
}

body::-webkit-scrollbar-thumb {
  background: #8d8d8d;
  border-radius: 10em;
}

.wrapper {
  position: relative;
  width: 94%;
  max-width: 100em;
  margin: auto;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

p {
  font-size: 1em;
  line-height: 24px;
  color: var(--text-color);
}

h1 {
  font-size: 2.8em;
  color: var(--text-color);
}

h2 {
  font-size: 2.4em;
  color: var(--text-color);
}

h3 {
  font-size: 2em;
  color: var(--text-color);
}

h4 {
  font-size: 1.8em;
  color: var(--text-color);
}
