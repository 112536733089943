.main {
	position: relative;
	padding: 4em 0;
}

span {
	font-size: 20px;
	color: var(--shadow-color);
}

.wrapper {
	position: relative;
	width: 94%;
	max-width: 100em;
	margin: auto;
	display: flex;
	gap: 2em;
	justify-content: space-between;
}

.carDetails {
	position: relative;
	width: 60%;
}

.carousel {
	position: relative;
	width: 100%;
	height: auto;
}

.slider {
	position: relative;
	margin: 2em 0 4em;
	width: 45em;
	height: auto;
	left: 50%;
	transform: translateX(-50%);
}

.image {
	position: relative;
	width: 100%;
	height: 30em;
	object-fit: cover;
	border-radius: 10px;
}

.details {
	position: relative;
}

.details p,
.details h1,
.details h2 {
	position: relative;
	margin: 0;
	padding: 0;
}

.details h1 {
	font-size: 1.8em;
}

.details h2 {
	font-size: 1.6em;
}

.moreDetails {
	position: relative;
	padding: 2em 0;
}

.moreDetails h2 {
	font-size: 1.8em;
	margin: 1em 0;
}

.table {
	position: relative;
	width: 100%;
	border-collapse: collapse;
}

.table tr {
	position: relative;
	line-height: 3.2em;
}

.table tr::after {
	position: absolute;
	content: '';
	background: var(--ash-color);
	height: 1px;
	width: 100%;
	bottom: 0;
	left: 0;
	pointer-events: none;
}

.contacts {
	position: relative;
	margin: 4em 0;
}

.contacts h2 {
	position: relative;
	width: 50%;
	font-size: 1.8em;
	margin-bottom: 0.6em;
}

.contacts p {
	position: relative;
	width: 70%;
	margin-bottom: 1em;
}

.contacts button {
	padding: 0.5em 1em;
	background-color: var(--secondary-color);
	color: var(--white-color);
	border: none;
	border-radius: 5px;
	cursor: pointer;
	font-size: 1em;
	transition: background-color 0.3s ease;
}

.contacts button:hover {
	background-color: var(--secondary-dark);
}

.purchaseForm {
	position: relative;
	width: 40%;
	height: fit-content;
	background: var(--fade-color);
	padding: 1.5em;
	border-radius: 10px;
}

.purchaseForm h2 {
	position: relative;
	padding: 0;
	margin: 0 0 1em;
	font-size: 1.8em;
}

.carForm {
	position: relative;
	padding: 0;
}

.carForm div {
	position: relative;
}

.carForm div .label {
	position: relative;
	font-size: 0.8em;
	font-weight: 600;
	line-height: 24px;
	color: var(--text-color);
	display: flex;
	align-items: center;
	gap: 1em;
	margin-bottom: 1em;
}

.carForm .label p {
	position: relative;
	width: 5em;
	padding: 0;
	margin: 0;
}

.carForm .label input {
	position: relative;
	width: calc(100% - 5em);
	font-size: 1.1em;
	padding: 0.6em 1.4em;
	border: none;
	border-radius: 10em;
	color: var(--text-color);
}

.carForm .label input:focus {
	outline: none;
}

.carForm button {
	position: relative;
	padding: 0.5em 1.2em;
	background-color: var(--text-color);
	color: var(--white-color);
	margin-top: 1em;
	border: none;
	border-radius: 10em;
	cursor: pointer;
	font-size: 1em;
	transition: background-color 0.3s ease;
}

.carForm button:hover {
	background-color: var(--hover-text-color);
}

.carForm div .textarea {
	position: relative;
	font-size: 0.8em;
	font-weight: 600;
	line-height: 24px;
	color: var(--text-color);
	display: flex;
	flex-direction: column;
}

.textarea textarea {
	position: relative;
	width: 100%;
	min-width: 100%;
	min-height: 12em;
	height: auto;
	border-radius: 10px;
	padding: 1em;
	border: none;
	resize: none;
}

.textarea textarea:focus {
	outline: none;
}

.disclaimer {
	position: relative;
	margin-top: 2em;
}

.disclaimer p {
	font-size: 0.8em;
	margin: 0;
}

.disclaimer .link {
	text-decoration: none;
	text-shadow: none;
	color: var(--text-color);
}

.disclaimer .link:hover {
	text-decoration: underline;
}

@media (max-width: 700px) {
	.wrapper {
		flex-direction: column;
		row-gap: 2em;
	}

	.carDetails {
		position: relative;
		width: 100%;
		margin: 0;
	}

	.carousel {
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		width: 92%;
		height: auto;
		margin-bottom: 2.4em;
	}

	.slider {
		width: 20em;
	}

	.image {
		height: 14em;
	}

	.details {
		position: relative;
	}

	.contacts p,
	.contacts h2,
	.purchaseForm {
		width: 100%;
	}
}
