.nav {
	padding: 4em 20px 20px;
	height: 100%;
	background-color: var(--white-color);
}

.nav h2 {
	position: relative;
	font-size: 1.4em;
	padding: 5px 10px;
	text-align: left;
	color: var(--secondary-color);
	margin: 0.1em 0 0.1em;
}

.nav ul {
	position: relative;
	list-style: none;
	padding: 0;
	display: flex;
	flex-direction: column;
	gap: 0.6em;
}

.nav ul li {
	position: relative;
	margin: 0;
}

.nav ul li:first-child::before,
.nav ul li:nth-child(2)::before {
	position: absolute;
	content: '';
	height: 1px;
	width: 100%;
	left: 0;
	bottom: -0.35em;
	background: var(--ash-color);
}

.nav ul li p,
.nav ul li a {
	position: relative;
	margin: 0;
	font-size: 1em;
	padding: 5px 10px;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s, color 0.3s;
	text-decoration: none;
	color: inherit;
	display: block;
}

.nav ul li p:hover,
.nav ul li a:hover {
	background: var(--secondary-light);
	color: var(--secondary-dark);
}

.nav ul li ul {
	position: relative;
	list-style: none;
	padding: 0;
	display: flex;
	flex-direction: column;
	gap: 0.6em;
}

.nav ul li ul li {
	position: relative;
	margin: 0;
}

.nav ul li ul li::before {
	display: none;
}

.nav ul li ul li p,
.nav ul li ul li a {
	position: relative;
	margin: 0;
	font-size: 1em;
	padding: 5px 1.5em;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s, color 0.3s;
	text-decoration: none;
	color: inherit;
	display: block;
}

.nav ul li ul li p:hover,
.nav ul li ul li a:hover {
	background: var(--secondary-light);
	color: var(--secondary-dark);
}

.active {
	background: var(--secondary-light);
	color: var(--secondary-dark);
}
