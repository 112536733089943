.main {
	position: relative;
	width: 100%;
	height: auto;
}

.form {
	position: relative;
	width: 100%;
	padding: 0;
	display: flex;
	flex-direction: row-reverse;
	gap: 1em;
}

.vehicleImage {
	position: relative;
	width: 50%;
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: start;
	gap: 1em;
}

.vehicleImage .imageItems {
	position: relative;
	width: 100%;
	display: flex;
	gap: 1em;
}

.imageItems p {
	position: relative;
	width: 8em;
}

.filePond {
	position: relative;
	width: 100%;
	height: auto;
}

.filePond--root {
	position: relative;
	border-radius: 10px;
	padding: 10px;
}

.filePond--label-action {
	color: var(--info-color);
	text-decoration: underline;
	cursor: pointer;
}

.customFilePondPreview {
	position: relative;
	width: 100%;
	height: auto;
	display: flex;
	gap: 0.5em;
	flex-wrap: wrap;
}

.customFilePondPreviewItem {
	position: relative;
	width: 5em;
	height: 10em;
	border-radius: 10px;
	overflow: hidden;
}

.customFilePondPreviewItem img {
	position: relative;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.vehicleImage p,
.featured p {
	position: relative;
	font-size: 0.8em;
	font-weight: 600;
	line-height: 24px;
	color: var(--text-color);
	padding: 0;
	margin: 0;
}

.imagePreviews {
	position: relative;
	width: fit-content;
	display: flex;
	gap: 1em;
	align-items: center;
	flex-wrap: wrap;
}

.imagePreviewContainer {
	position: relative;
	width: 10em;
	height: 6.7em;
	margin: 0 0 1em;
}

.imagePreview {
	position: relative;
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 10px;
}

.removeImageButton {
	position: absolute;
	width: fit-content;
	right: 0;
	font-size: 0.6em;
	font-weight: 800;
	padding: 0.3em 0.5em;
	margin: 0;
	background: var(--text-color);
	color: var(--white-color);
	border-radius: 10em;
}

.formFields {
	position: relative;
	width: 50%;
	display: flex;
	gap: 1em;
	flex-direction: column;
	align-items: left;
}

.formField {
	position: relative;
}

.formField .label {
	position: relative;
	font-size: 0.8em;
	font-weight: 600;
	color: var(--text-color);
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 1em;
}

.formField .description {
	position: relative;
	font-size: 0.8em;
	font-weight: 600;
	color: var(--text-color);
	display: flex;
	align-items: flex-start;
	gap: 1em;
}

.formField label p {
	position: relative;
	width: 8em;
	padding: 0;
	margin: 0;
}

.inputText {
	position: relative;
	width: calc(100% - 8em);
	font-size: 1.1em;
	padding: 0.6em 1.4em;
	margin: 0;
	border: none;
	border-radius: 10em;
	color: var(--text-color);
	box-shadow: 0 2px 4px var(--ash-color);
}

.inputText:focus {
	outline: none;
}

.textarea {
	position: relative;
	width: calc(100% - 8em);
	height: 10em;
	font-size: 1.1em;
	padding: 0.4em 0.8em;
	margin: 0;
	border: none;
	border-radius: 10px;
	color: var(--text-color);
	box-shadow: 0 2px 4px var(--ash-color);
	resize: none;
}

.textarea:focus {
	outline: none;
}

.featured {
	position: relative;
	width: fit-content;
	display: flex;
	gap: 1em;
	align-items: center;
}

.featured input {
	position: relative;
	height: 1.2em;
	width: 1.2em;
}

.submitButton {
	position: relative;
	padding: 0.6em 1.5em;
	font-size: 1em;
	background: var(--secondary-color);
	color: var(--white-color);
	border: none;
	border-radius: 10em;
	cursor: pointer;
	width: fit-content;
}

.submitButton:hover {
	background: var(--secondary-dark);
}

.feedback {
	position: absolute;
	top: 5%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: var(--white-color);
	padding: 0.8em 2.4em;
	border: 1px solid var(--border-color);
	border-radius: 10em;
	box-shadow: 0 1em 4em #0000002f;
	z-index: 2;
}

@media (max-width: 700px) {
	.vehicleImage .imageItems {
		flex-direction: column;
	}

	.imageItems p {
		width: 100%;
	}

	.filePond {
		width: 100%;
	}
}
