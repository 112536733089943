.newCars,
.usedCars {
	position: relative;
	width: 100%;
	padding: 4em 0;
}

.wrapper {
	position: relative;
	width: 94%;
	max-width: 100em;
	margin: auto;
}

.filters {
	position: relative;
	width: fit-content;
	display: flex;
	align-items: center;
	gap: 0.4em;
	margin: 1em 0;
}

.dropdown {
	width: 12em;
	padding: 8px 10px;
	border: 1px solid var(--border-color);
	border-radius: 0.6em;
	font-size: 0.9em;
}

.filters input:focus {
	outline: none;
}

.searchButton {
	background: var(--secondary-color);
	width: 12em;
	padding: 10px 20px;
	color: var(--white-color);
	border: none;
	cursor: pointer;
	font-size: 0.9em;
	border-radius: 0.6em;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.5em;
}

.searchButton:hover {
	background-color: var(--secondary-dark);
}

.featuredInventory {
	position: relative;
	padding: 0;
	margin: 2em 0 4em;
}

.newUsedFilters {
	padding: 0 0 1em;
	display: flex;
	gap: 0.8em;
	overflow-x: auto;
	white-space: nowrap;
}

.filterButton {
	background: var(--ash-color);
	border: none;
	padding: 0.6em 1.4em;
	border-radius: 10em;
	color: var(--text-color);
	font-weight: 600;
	cursor: pointer;
	display: inline-block;
}

.newUsedFilters .active {
	background: var(--text-color);
	color: var(--white-color);
}

.results,
.vehicleList {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	gap: 1em;
}

.vehicle {
	position: relative;
	border: 1px solid var(--ash-color);
	border-radius: 0.6em;
	cursor: pointer;
	max-width: 20em;
	width: 15em;
	flex-basis: 24%;
	height: fit-content;
	overflow: hidden;
	display: block;
}

.vehicle:hover {
	position: relative;
	border: 1px solid var(--shadow-color);
	transition: 0.4s;
}

.vehicle:hover p {
	text-decoration: underline;
	font-weight: 400;
}

.vehicle .image {
	position: relative;
	width: 100%;
	max-height: 13.33em;
	height: 10em;
	object-fit: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.vehicle .image img {
	position: relative;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.vehicle .image .offer {
	position: absolute;
	display: block;
	background: var(--secondary-color);
	color: var(--white-color);
	font-weight: 500;
	font-size: 0.7em;
	text-decoration: none;
	letter-spacing: 2px;
	top: 1em;
	left: 1em;
	border-radius: 8px;
	padding: 0 0.6em;
}

.hidden {
	display: none;
}

.vehicle:hover p.offer {
	text-decoration: none;
}

.vehicle .desc {
	position: relative;
	min-height: calc(100% - 10em);
	height: auto;
	text-align: left;
	padding: 0.6em 0.8em;
}

.vehicle .desc h3 {
	position: relative;
	width: fit-content;
	font-size: 1.2em;
}

.vehicle:hover h3 {
	text-decoration: underline 3px var(--secondary-color);
}

.descFlex {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 0.3em;
}

.vehicle .desc h4 {
	position: relative;
	font-size: 1em;
}

.vehicle .desc h4 span {
	position: relative;
	font-size: 0.8em;
}

.vehicle .desc p {
	position: relative;
	font-size: 1em;
	margin: 0;
	padding: 0;
}

.vehicle .desc .icon {
	position: relative;
	color: var(--shadow-color);
	font-size: 0.88em;
	margin: 0 0.4em;
}

@media (max-width: 700px) {
	.filters {
		flex-wrap: wrap;
	}

	.results,
	.vehicleList {
		flex-direction: column;
	}

	.vehicle {
		min-width: 100%;
		width: 100%;
	}

	.vehicle .image {
		height: 13.33em;
	}
}
