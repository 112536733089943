.profile {
	position: relative;
	width: 100%;
	height: auto;
	padding: 1em 1.4em;
	background-color: var(--white-color);
}

.personalDetails {
	margin-bottom: 2em;
}

.personalDetails p {
	font-size: 1em;
	color: var(--text-color);
}

.message {
	margin-top: 1em;
	color: var(--danger-color);
}
