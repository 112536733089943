.nav {
  position: fixed;
  top: 0;
  left: 0;
  background: var(--white-color);
  width: 100%;
  height: 3.4em;
  z-index: 10;
  box-shadow: 0 0 8px var(--fade-color);
}

.fest {
  position: relative;
  left: 0;
  top: 0;
  width: 100vw;
  height: 0em;
}

.image,
.image2,
.image3 {
  position: relative;
  height: 0;
}

.fest .wrapper .image .imgleft {
  position: relative;
  left: -3.8em;
  top: -2.5em;
  width: 8em;
}

.fest .wrapper .image2 .imgcenter {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  top: -0.2em;
  width: 8em;
}

.fest .wrapper .image3 .imgright {
  position: relative;
  right: -3.4em;
  top: -2.5em;
  width: 8em;
  transform: scaleX(-1);
}

.wrapper {
  position: relative;
  width: 94%;
  max-width: 100em;
  margin: auto;
  display: flex;
  gap: 4em;
  justify-content: space-between;
  align-items: center;
}

.link p {
  position: relative;
  margin: 0;
  font-size: 0.94em;
}

.link p:hover {
  text-decoration: underline;
}

.logout {
  position: relative;
  background: none;
  border: none;
  cursor: pointer;
}

.logout p {
  font-size: 1.1em;
}

.logout p:hover {
  text-decoration: underline;
}

.left {
  position: relative;
  height: 100%;
  width: fit-content;
}

.left .logo {
  position: relative;
  height: 3.4em;
  width: 6.4em;
}

.left .logo img {
  position: relative;
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.right {
  position: relative;
  width: 100%;
}

.menuIcon {
  display: none;
  cursor: pointer;
  font-size: 1.5em;
}

.floatMenu {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.menu,
.account {
  display: flex;
  gap: 1em;
}

.showMenu {
  display: flex !important;
}

@media (max-width: 768px) {
  .fest .wrapper .image .imgleft {
    position: relative;
    left: -2.5em;
    top: -2em;
    width: 8em;
  }

  .fest .wrapper .image2 {
    display: none;
  }

  .fest .wrapper .image3 {
    display: none;
  }

  .left {
    width: fit-content;
  }

  .right {
    position: relative;
    width: fit-content;
  }

  .floatMenu {
    position: absolute;
    display: none;
    background: var(--white-color);
    flex-direction: column;
    row-gap: 1.7em;
    padding: 1.5em;
    border-radius: 10px;
    box-shadow: 0 0 8px var(--shadow-color);
    top: 2.8em;
    right: 0.2em;
    width: 14em;
    z-index: 100;
  }

  .menu,
  .account {
    position: relative;
    width: fit-content;
    flex-direction: column;
    text-align: left;
  }

  .menuIcon {
    display: block;
  }

  .menu a,
  .account a {
    width: fit-content;
    font-size: 1.3em;
    font-weight: 500;
  }

  .menu::after {
    position: absolute;
    content: "";
    width: 11em;
    height: 1px;
    border-radius: 1em;
    left: 0;
    bottom: -1em;
    background: var(--shadow-color);
  }
}
