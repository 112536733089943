.home {
	position: relative;
	width: 100%;
	height: auto;
	background: var(--white-color);
}

.wrapper {
	position: relative;
	width: 94%;
	max-width: 100em;
	margin: auto;
}

.hero {
	position: relative;
	width: 100%;
	height: 70vh;
	max-height: 40em;
	text-align: center;
	color: var(--white-color);
	border-radius: 0 0 1em 1em;
	overflow: hidden;
}

.videoBg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	z-index: 3;
	background: url('../assets/hero.png') no-repeat center center;
	background-size: cover;
}

.text {
	position: relative;
	height: auto;
	width: fit-content;
	text-align: center;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 4;
}

.text h1 {
	position: relative;
	width: fit-content;
	color: var(--white-color);
	font-size: 3.54em;
	display: flex;
	align-items: center;
	gap: 0.5em;
}

.search {
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	width: fit-content;
	display: flex;
	align-items: center;
	gap: 0.4em;
	margin: 1em 0;
}

.search .dropdown {
	width: 12em;
	padding: 10px;
	border: none;
	border-radius: 0.6em;
	font-size: 0.9em;
}

.search input:focus {
	outline: none;
}

.searchButton {
	background: var(--secondary-color);
	width: 12em;
	padding: 10px 20px;
	color: var(--white-color);
	border: none;
	cursor: pointer;
	font-size: 0.9em;
	border-radius: 0.6em;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.5em;
}

.popularInventory,
.featuredInventory {
	position: relative;
	padding: 0;
	margin: 2em 0 4em;
}

.categoryButtons,
.newUsedFilters {
	padding: 0 0 1em;
	display: flex;
	gap: 0.8em;
	overflow-x: auto;
	white-space: nowrap;
}

.categoryButton,
.filterButton {
	background: var(--ash-color);
	border: none;
	padding: 0.6em 1.4em;
	border-radius: 10em;
	color: var(--text-color);
	font-weight: 600;
	cursor: pointer;
	display: inline-block;
}

.categoryButtons .active {
	background: var(--text-color);
	color: var(--white-color);
}

.newUsedFilters .active {
	background: var(--text-color);
	color: var(--white-color);
}

.vehicleList {
	position: relative;
	width: 100%;
	display: grid;
	grid-gap: 1em;
	grid-template-columns: repeat(4, 1fr);
}

.vehicle {
	position: relative;
	border: 1px solid var(--ash-color);
	border-radius: 0.6em;
	cursor: pointer;
	height: fit-content;
	overflow: hidden;
	display: none;
}

.vehicle:nth-child(-n + 8) {
	display: block;
}

.vehicle:hover {
	position: relative;
	border: 1px solid var(--shadow-color);
	transition: 0.4s;
}

.vehicle:hover p {
	text-decoration: underline;
	font-weight: 400;
}

.vehicle .image {
	position: relative;
	width: 100%;
	max-height: 13.33em;
	height: 10em;
	object-fit: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.vehicle .image img {
	position: relative;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.vehicle .image .offer {
	position: absolute;
	display: block;
	background: var(--secondary-color);
	color: var(--white-color);
	font-weight: 500;
	font-size: 0.7em;
	text-decoration: none;
	letter-spacing: 2px;
	top: 1em;
	left: 1em;
	border-radius: 8px;
	padding: 0 0.6em;
}

.hidden {
	display: none;
}

.vehicle:hover p.offer {
	text-decoration: none;
}

.vehicle .desc {
	position: relative;
	min-height: calc(100% - 10em);
	height: auto;
	width: 100%;
	text-align: left;
	padding: 0.6em 0.8em;
}

.vehicle .desc h3 {
	position: relative;
	width: fit-content;
	font-size: 1.2em;
}

.vehicle:hover h3 {
	text-decoration: underline 3px var(--secondary-color);
}

.descFlex {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 0.3em;
}

.vehicle .desc h4 {
	position: relative;
	font-size: 1em;
}

.vehicle .desc h4 span {
	position: relative;
	font-size: 0.8em;
}

.vehicle .desc p {
	position: relative;
	font-size: 1em;
	margin: 0;
	padding: 0;
}

.vehicle .desc .icon {
	position: relative;
	color: var(--shadow-color);
	font-size: 0.88em;
	margin: 0 0.4em;
}

.offerList,
.reviewList {
	display: flex;
	justify-content: space-around;
}

.vehicle,
.offer,
.review {
	text-align: center;
}

.shomo {
	position: relative;
	margin: 2em 0;
	width: fit-content;
}

.shomo button,
.shomo .btn {
	position: relative;
	border: 1px solid var(--shadow-color);
	font-size: 1em;
	font-weight: 500;
	color: var(--shadow-color);
	background: none;
	padding: 4px 12px 5px;
	border-radius: 10em;
}

.shomo button:hover,
.shomo .btn:hover {
	cursor: pointer;
	color: var(--white-color);
	background: var(--shadow-color);
}

.wrapper h2 {
	position: relative;
	color: var(--text-color);
	font-size: 1.4em;
	margin-bottom: 0.6em;
}

.brands {
	position: relative;
	overflow: hidden;
	width: 100%;
	padding: 4em 0;
	text-align: center;
}

.brandListWrapper {
	position: relative;
	width: 100%;
	overflow: hidden;
}

.brandList {
	display: flex;
	animation: scrollLeft 20s linear infinite;
	width: calc(200%);
	gap: 3em;
	padding: 2em 0;
}

.brandList:hover {
	animation-play-state: paused;
}

.brand {
	flex: 0 0 auto;
	margin-right: 20px;
	text-align: center;
}

.brand img {
	max-width: 10em;
	max-height: 4em;
	filter: grayscale(100%);
	transition: 0.3s;
}

.brand img:hover {
	filter: grayscale(0%);
}

@keyframes scrollLeft {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-50%);
	}
}

.brand p {
	font-size: 1.2em;
	color: var(--text-color);
}

.whyKenjap {
	position: relative;
	padding: 4em 0;
	text-align: center;
}

.whyKenjap .reasons {
	text-align: left;
	display: grid;
	grid-template-columns: 30% 30% 30%;
	justify-content: space-between;
	margin-top: 2.5em;
}

.reasons .reason {
	position: relative;
	height: auto;
}

.reason .image {
	position: relative;
}

.reason .image img {
	max-width: 70%;
	min-height: 12em;
	max-height: 12em;
}

.reason .txt {
	position: relative;
	padding: 1em;
}

.txt h4 {
	font-size: 1em;
	font-weight: 800;
	margin-bottom: 1em;
}

.txt h3 {
	font-size: 1.4em;
	margin-bottom: 0.2em;
}

.txt p {
	padding: 0;
	margin: 0;
}

.reviews {
	position: relative;
	padding: 2em 0;
	margin: 2em 0;
	text-align: center;
}

.reviewList {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	gap: 2em;
	margin-top: 2.5em;
}

.review {
	position: relative;
	background-color: var(--white-color);
	border-radius: 10px;
	padding: 1.5em;
	text-align: left;
	box-shadow: 0 0 8px var(--fade-color);
}

.review h3 {
	font-size: 1.1em;
	line-height: 24px;
}

.review h4 {
	font-size: 1em;
	line-height: 24px;
}

.review p {
	position: relative;
	padding: 0.5em 1em;
	background: var(--light-background-color);
	margin: 0.2em 0;
	border-radius: 10px;
}

.review p:first-child {
	font-weight: bold;
}

.review .rating {
	position: relative;
	display: flex;
	align-items: center;
	gap: 0.4em;
}

.rating p {
	font-size: 0.8em;
	background: none;
	padding: 0;
}

.review .rating .rate {
	font-size: 0.6em;
	color: var(--warning-color);
}

.sellcar {
	position: relative;
	margin: 2em 0;
	background-color: var(--light-background-color);
	border-radius: 10px;
	display: flex;
	justify-content: space-between;
	overflow: hidden;
}

.sellcar .txt {
	padding: 2em;
	z-index: 1;
}

.sellcar .image {
	position: relative;
	height: auto;
}

.sellcar .image img {
	position: absolute;
	top: -1.5em;
	right: 0;
	max-height: 15em;
	min-height: 100%;
	width: auto;
}

.sellcar p {
	font-weight: 600;
	margin-bottom: 1em;
}

.sellcar button {
	padding: 0.5em 1em;
	background-color: var(--secondary-color);
	color: var(--white-color);
	border: none;
	border-radius: 5px;
	cursor: pointer;
	font-size: 1em;
	transition: background-color 0.3s ease;
}

.sellcar button:hover {
	background-color: var(--secondary-dark);
}

.footer {
	position: relative;
}

/* Media Queries */
@media (max-width: 700px) {
	.hero {
		min-height: 25em;
		max-height: 25em;
	}

	.text {
		width: 80%;
	}

	.text h1 {
		font-size: 2em;
	}

	.search {
		flex-wrap: wrap;
		padding: 0 1em;
	}

	.search .dropdown {
		width: 8.8em;
	}

	.searchButton {
		width: 8.8em;
	}

	.vehicleList {
		grid-template-columns: 100%;
	}

	.vehicle {
		min-width: 100%;
		width: 100%;
	}

	.vehicle .image {
		height: 20em;
	}

	.vehicle:nth-child(-n + 4) {
		display: block;
	}

	.vehicle:nth-child(n + 5) {
		display: none;
	}

	.brandList {
		animation: scrollLeft 5s linear infinite;
	}

	.whyKenjap .reasons {
		grid-template-columns: 100%;
	}

	.reviewList {
		grid-template-columns: 100%;
	}

	.sellcar .image {
		opacity: 0.4;
	}
}
