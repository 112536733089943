.carDetail {
	position: relative;
	width: 100%;
	height: auto;
}

.carDetail h2 {
	position: relative;
	font-size: 1.8em;
	margin: 0;
}

.carDetail .editDelete {
	position: relative;
	padding: 0.4em 0 0.8em;
	display: flex;
	align-items: center;
	gap: 0.6em;
}

.editButton {
	padding: 0.2em 1.4em 0.4em;
	background-color: var(--primary-color);
	color: var(--white-color);
	border: none;
	border-radius: 10em;
	cursor: pointer;
	font-size: 0.8em;
	transition: background-color 0.3s ease;
}

.editButton:hover {
	background-color: var(--hover-text-color);
}

.deleteButton {
	padding: 0.2em 1.4em 0.4em;
	background-color: var(--secondary-color);
	color: var(--white-color);
	border: none;
	border-radius: 10em;
	cursor: pointer;
	font-size: 0.8em;
	transition: background-color 0.3s ease;
}

.deleteButton:hover {
	background-color: var(--hover-text-color);
}

.images {
	position: relative;
	width: 20em;
	height: 10em;
}

.imageContainer .image {
	position: relative;
	width: 100%;
	height: 10em;
	object-fit: cover;
	border-radius: 10px;
}

.imageContainer .image img {
	position: relative;
	width: 100%;
	height: 100%;
	object-fit: cover;
	padding: 0;
	margin: 0;
}

.imageContainer .deleteButton {
	position: absolute;
	width: fit-content;
	right: 0;
	top: 0;
	font-size: 0.6em;
	font-weight: 800;
	padding: 0.4em 0.6em;
	margin: 0;
	background: var(--text-color);
	color: var(--white-color);
	border-radius: 10em;
}

.imageContainer .deleteButton:hover {
	cursor: pointer;
}

.carDetail .edit {
	position: relative;
	width: fit-content;
	display: flex;
	align-items: center;
	gap: 1em;
	margin: 0.5em 0;
}

.carDetail .edit button {
	position: relative;
	padding: 0.5em 1.2em;
	background-color: var(--text-color);
	color: var(--white-color);
	border: none;
	border-radius: 10em;
	cursor: pointer;
	font-size: 0.8em;
	transition: background-color 0.3s ease;
}

.carDetail .edit button:hover {
	background-color: var(--hover-text-color);
}

.carDetail .details {
	position: relative;
	margin: 2.4em 0 0;
}

.editForm .formGroup {
	margin-bottom: 15px;
}

.editForm .formGroup label {
	display: block;
	margin-bottom: 5px;
}

.editForm .formGroup input,
.editForm .formGroup textarea {
	width: 100%;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
}

.editForm .buttonGroup {
	display: flex;
	gap: 1em;
	margin-top: 1em;
}

.editForm .saveButton,
.editForm .cancelButton {
	padding: 10px 15px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
}

.editForm .saveButton {
	background: #28a745;
	color: white;
}

.editForm .cancelButton {
	background: #dc3545;
	color: white;
}

.editForm .error {
	color: red;
	font-weight: bold;
	margin-bottom: 15px;
}

.theDescription {
	position: relative;
	min-height: 18em;
	line-height: 24px;
	font-size: 1em;
}
